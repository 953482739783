.divider-container {
  text-align: center;
  padding: 20px 0;
  font-size: 35px;
  /* border-bottom: 0.5px solid rgba(0, 0, 0, 0.1); */
  font-weight: bold;
  color: black;
  margin: 10px 0;
  /* position: absolute;
  top: 65%; */
}

.slide-image-container {
  width: 100%;
  height: 500px;
}

.slide-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -webkit-filter: blur(2px);
}

.slide-container {
  padding: 0 3%;
  position: relative;
  margin: 20px 0;
}

@media (min-width: 992px) {
  .slide-container {
    padding: 0 20%;
  }

  .divider-container {
    font-size: 50px;
    /* border-bottom: 0.5px solid rgba(0, 0, 0, 0.1); */
    font-weight: bold;
    color: black;
    margin: 10px 0;
    /* position: absolute;
    top: 0%;
    left: 32%; */
  }
}
