.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 0;
}

.product-card-container {
  width: calc(50% - 20px);
  margin: 10px;
  background-color: #f4f4f4;
  box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.2);
  height: 300px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.product-image-container {
  width: 100%;
  height: 180px;
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.product-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-image-container:hover {
  transform: scale(1.1);
}

.product-card-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.product-title {
  color: #fff;
  font-size: 20px;
  /* font-family: 'Bakbak One', cursive; */
}
.product-price {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  /* font-family: 'Bakbak One', cursive; */
}

@media (min-width: 768px) {
  .product-card-container {
    width: calc(33.3% - 20px);
  }

  .product-image-container {
    width: 100%;
    height: 290px;
    background-color: #fff;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }

  .product-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (min-width: 992px) {
  .product-card-container {
    width: calc(25% - 20px);
    height: 400px;
  }
}

@media (min-width: 1700px) {
  .product-card-container {
    width: calc(20% - 20px);
    height: 400px;
  }
}
