.app-container {
  width: calc(100% - 20px);
  margin: 0 10px;
}

@media (min-width: 960px) {
  .app-container {
    width: calc(90% - 10%);
    margin: 0 5%;
  }
}

/* @media (min-width: 1700px) {
  .app-container {
    width: calc(80% - 20%);
    margin: 0 10%;
  }
} */
