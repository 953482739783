@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: #f8f9fc;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Lobster', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-H,
.about-bottom-container {
  padding: 0 3%;
}

@media (min-width: 960px) {
  .laptop-H,
  .about-bottom-container {
    padding: 0 5%;
  }
}

@media (min-width: 1600px) {
  .desktop-H,
  .about-bottom-container {
    padding: 0 10%;
  }
}

@media (min-width: 2000px) {
  .desktop-2k,
  .about-bottom-container {
    padding: 0 20%;
  }
}
