.banner-container {
  height: 40vh;

  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  background: url('../../assets/images/white-banner.png') no-repeat center
    center;
  background-size: cover;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
}
.banner-container h2 {
  color: #000;
  margin: 0;
  margin-bottom: 30px;
  align-self: flex-end;
  text-align: center;
  font-size: 25px;
}
@media (min-width: 992px) {
  .banner-container {
    height: 50vh;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .banner-container h2 {
    font-size: 60px;
  }
}

@media (min-width: 1900px) {
  .banner-container {
    background-position-y: -350px;
  }

  .banner-container h2 {
    align-self: flex-end;
    text-align: center;
    margin-bottom: 50px;
    font-size: 80px;
  }
}
