.header-container {
  display: flex;
  flex-direction: row;
  height: 90px;

  /* background-color: rgba(0, 0, 0, 0.4); */
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav {
  display: flex;
  flex-direction: row;
  display: none;
}

.burger-icon {
  display: block;
  color: #bd9651;
  cursor: pointer;
}

.nav-links {
  font-size: 10px;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

.nav-links:hover {
  color: #bd9651;
}

.header-logo {
  width: 130px;
  height: 130px;
}
.header-logo img {
  width: 100%;
  height: 100%;
}

.drawer-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  font-size: 20px;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.drawer-list:hover {
  color: #bd9651;
}

@media (min-width: 960px) {
  .header-container {
    padding: 0 10%;
    height: 160px;
  }

  .nav {
    display: flex;
  }

  .burger-icon {
    display: none;
  }

  .header-logo {
    width: 450px;
    height: 450px;
  }
  .header-logo img {
    width: 100%;
    height: 100%;
  }

  /* .header-logo {
    width: 200px;
  } */
  .nav-links {
    font-size: 17px;
    color: #000;
    margin-left: 50px;
  }
}

@media (min-width: 1700px) {
  .header-container {
    height: 160px;
  }
  .nav-links {
    font-size: 17px;
  }
}
