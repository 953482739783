.footer-container {
  display: flex;
  flex-direction: column;
}
.footer-side-left {
  height: 300px;
  background-color: #bd9651;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  box-sizing: border-box;
  background: rgb(199, 150, 64);
  background: linear-gradient(
    90deg,
    rgba(199, 150, 64, 1) 0%,
    rgba(238, 190, 105, 1) 35%,
    rgba(108, 74, 13, 1) 100%
  );
}

.footer-side-right {
  height: 100%;
  background-color: #1b1c22;
  background: rgb(33, 33, 37);
  background: linear-gradient(
    180deg,
    rgba(33, 33, 37, 1) 0%,
    rgba(27, 28, 29, 1) 35%,
    rgba(40, 40, 42, 1) 100%
  );
}

.footer-side-left h5 {
  color: white;
  font-size: 25px;
  font-weight: 400;
  transform: translateY(-10px);
  /* font-family: 'Bakbak One', cursive; */
}

.footer-side-left p {
  color: white;
  font-size: 40px;
  width: 55%;
  /* font-family: 'Bakbak One', cursive; */

  padding: 0;
  margin: 0;
}
.footer-left-side-btn {
  padding: 10px;
  color: #fff;
  border: 3px solid #fff;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  /* font-family: 'Bakbak One', cursive; */
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.footer-left-side-btn:hover {
  transform: scale(1.1);
}

.footer-side-right {
  padding: 50px;
  box-sizing: border-box;
}

.footer-heading {
  font-size: 25px;
  color: darkgrey;
  font-weight: bold;
  margin-bottom: 20px;
  /* font-family: 'Bakbak One', cursive; */
}

.footer-links {
  color: darkgrey;
  margin-bottom: 10px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  /* font-family: 'Bakbak One', cursive; */
}

.footer-links:hover {
  color: #bd9651;
}

.footer-social-links {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
}

.footer-social-links div {
  width: 40px;
  height: 40px;

  border-radius: 20px;
  margin-right: 5px;
  transition: transform 0.2s ease-in-out;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-social-links div:hover {
  transform: translateY(-2px);
}

.footer-copyright {
  padding: 20px 0;
  color: #fff;
  text-align: center;
  /* font-family: 'Bakbak One', cursive; */
}

@media (min-width: 768px) {
  .footer-side-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-box-1 {
    flex: 1;
  }

  .footer-box-2 {
    flex: 1;
    margin-top: -40px;
  }

  .footer-side-left {
    height: 350px;
  }

  .footer-side-left h5 {
    font-size: 24px;

    transform: translateY(-10px);
  }

  .footer-side-left p {
    font-size: 60px;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .footer-container {
    flex-direction: row;
    align-items: flex-end;
  }

  .footer-side-left {
    flex: 1;
    height: 450px;
    border-top-right-radius: 10px;
    padding-left: 8%;
  }

  .footer-side-right {
    flex: 1;
    height: 350px;
    align-items: center;
  }

  .footer-side-left h5 {
    font-size: 25px;
  }

  .footer-side-left p {
    font-size: 70px;
    width: 60%;
    margin: 0;
  }
  .footer-left-side-btn {
    height: 40px;
    width: 200px;
    align-self: flex-start;
    margin-top: 10px;
  }
}

@media (min-width: 1500px) {
  .footer-container {
    flex-direction: row;
    align-items: flex-end;
  }

  .footer-side-right {
    flex: 1;
    height: 350px;
    align-items: center;
  }

  .footer-side-left p {
    font-size: 90px;
    width: 60%;
  }
}

@media (min-width: 1700px) {
  .footer-side-left {
    height: 550px;

    padding-left: 8%;
  }

  .footer-side-right {
    height: 450px;
  }

  .footer-side-left h5 {
    font-size: 40px;
  }

  .footer-side-left p {
    font-size: 90px;
    width: 60%;
    margin: 0;
  }
}
