.contact-detail {
  padding: 100px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-box {
  background-color: #fff;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  border-top: 5px solid #bd9651;
}

.contact-social-icon {
  position: absolute;
  top: -35px;
  left: 20;
  width: 70px;
  height: 70px;
  background-color: #f8f8f8;
  border-radius: 35px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bd9651;
}

.contact-social-icon:hover {
  background-color: #bd9651;
  color: #fff;
}

.box-heading {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.box-text {
  color: grey;
  font-size: 16px;
  font-weight: 400;
}

@media (min-width: 568px) {
  .contact-box {
    height: 250px;
  }
}
@media (min-width: 768px) {
  .contact-detail {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .contact-box {
    width: calc(50% - 10px);
    margin-right: 10px;
  }

  .box-heading {
    font-size: 20px;
  }

  .box-text {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .contact-box {
    width: calc(33.3% - 10px);
    margin-right: 10px;
    height: 180px;
  }
}

@media (min-width: 1900px) {
  .contact-box {
    height: 220px;
  }

  .box-heading {
    font-size: 25px;
  }

  .box-text {
    font-size: 18px;
  }
}
