.artist-card2-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  background-color: transparent;
  height: 600px;
  justify-content: flex-end;
  margin: 5px;
  border-radius: 7px;
  /* background-image: url("");
	background-size: cover;
	background-repeat: no-repeat; */

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}

.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
  opacity: 0.9;
}

.artist-card2-container:hover .card-image-container img {
  transform: scale(1.2);
}

.artists-card2-details {
  padding-bottom: 50px;
  padding-left: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
  /* background-color: #c6243d; */
  opacity: 1;
  box-sizing: border-box;
  position: relative;
  /* background-color: rgba(104, 100, 100, 0.5); */
}

.artist-card2-container:hover .artists-card2-details {
  background-color: #bd9651;
  opacity: 0.8;
  transform: translateY(0px);
}

.artists-card2-details h3 {
  font-size: 30px;
  color: #fff;
  /* font-family: 'Bakbak One', cursive; */
}
.artists-card2-details p {
  color: #fff;
  font-size: 20px;
  text-align: justify;
  margin-right: 35px;
  margin-bottom: 0;
}
/* .artist-card-bottom-animation {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
} */

.artist-card-btn {
  background-color: orangered;

  padding: 10px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 20px;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 768px) {
  .artist-card2-container {
    width: calc(50% - 10px);
    height: 450px;
  }
  .artist-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .artist-card2-container {
    width: calc(25% - 10px);
    height: 330px;
  }
  /* .artists-card2-details {
		transform: translateY(90px);
	} */
  .artists-card2-details h3 {
    font-size: 30px;
    color: #fff;
  }
  .artists-card2-details p {
    color: #fff;
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .artist-card2-container {
    height: 650px;
  }
  .artists-card2-details h3 {
    font-size: 30px;
  }
  .artists-card2-details p {
    font-size: 20px;
  }
}

@media (min-width: 2000px) {
  .artist-card2-container {
    width: calc(20% - 10px);
    height: 650px;
  }
}
/* .artists-card2-details {
	transform: translateY(100px);
} */
.artist-card-container {
  width: calc(100% - 20px);
  margin: 10px;
  background-color: #f4f4f4;
  height: 400px;
  border-top-left-radius: 10px;
  border-radius: 7px;
  /* border-left: 5px solid greenyellow; */
  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-end;

  box-sizing: border-box;
  background: url('../../assets//images//cardImage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -20%;
  opacity: 0.8;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.artist-card-container:hover {
  background-color: orangered;
  opacity: 0.8;
}
.artist-detail {
  z-index: 1;
  color: #fff;
  background-color: rgba(255, 69, 0, 0.7);
  padding: 10px;
  transition: all 0.3s ease-in-out;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
}

.artist-card-container:hover .artist-detail {
  min-height: 100%;
  justify-content: center;
}

.artist-detail h3 {
  color: #fff;
  font-size: 30px;
}

.artist-detail p {
  font-size: 15px;
}

@media (min-width: 960px) {
  .artist-card-container {
    width: calc(32.5% - 10px);
    margin: 10px;
  }
}

.artist-detail p {
  font-size: 18px;
}

@media (min-width: 1600px) {
  .artist-card-container {
    width: calc(25% - 5px);
    margin: 10px 2.5px;
    height: 650px;
  }
}
