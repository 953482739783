.about-us-top-section {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.top-section-image-container {
  width: 100%;
  height: 500px;
  border-radius: 7px;
  overflow: hidden;
}
.top-section-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: contain;
}
.about-top-section-detail {
  margin: 20px 0;
}

.about-us-top-section div {
  margin: 10px 0;
}
.about-top-section-detail div h3 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}
.about-top-section-detail div p {
  font-size: 17px;
  color: grey;
  text-align: justify;
}

.about-top-section-detail div h4 {
  font-size: 22px;
  margin: 20px 0;
  font-weight: 500;
}

.about-us-middle-section {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 100%;
}

.middle-section-image-container {
  width: 100%;
  height: 55%;
}

.middle-section-image-container img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.middle-section-detail {
  background-color: #272527;
  height: 45%;
  padding: 50px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  flex-direction: column;
}

.middle-section-detail h3 {
  color: #f43126;
  font-size: 30px;
}
.middle-section-detail p {
  color: #f8f8f8;
  margin: 10px 0;
}
.middle-section-btn {
  width: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px;
}

.about-bottom-container h2 {
  font-size: 40px;
}

.about-bottom-container li {
  font-size: 20px;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
}

@media (min-width: 566px) {
  .top-section-image-container {
    height: 630px;
  }

  .about-top-section-detail div h3 {
    font-size: 60px;
  }
  .about-top-section-detail div p {
    font-size: 19px;
  }

  .about-top-section-detail div h4 {
    font-size: 28px;
    margin: 20px 0;
  }

  .about-us-middle-section {
    height: 600px;
  }
}

@media (min-width: 800px) {
  .about-us-top-section {
    margin-top: 80px;
  }

  .top-section-image-container {
    width: 90%;
    height: 650px;
    align-self: center;
  }
  .about-top-section-detail {
    padding: 10px 50px;
  }
  .about-top-section-sub-heading {
    display: flex;
    flex-direction: row;
  }
  .about-top-section-sub-heading div {
    padding-right: 25px;
  }

  .middle-section-image-container {
    height: 90%;
  }

  .middle-section-detail h3 {
    font-size: 35px;
  }
  .middle-section-detail p {
    font-size: 20px;
    padding-right: 40px;
    margin: 20px 0;
  }

  .middle-section-detail {
    padding: 60px;
  }

  .about-us-middle-section {
    margin-bottom: 20%;
  }
}

@media (min-width: 1100px) {
  .top-section-image-container {
    min-width: 30%;
    height: 600px;
    align-self: center;
  }
  .about-us-top-section {
    flex-direction: row;
  }

  .about-top-section-detail > div {
    padding: 10px 50px;
  }

  .middle-section-image-container {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .about-us-middle-section {
    position: relative;
  }
  .middle-section-detail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 450px;
    width: 70%;
    padding: 100px;
    border-radius: 7px;
  }
}

@media (min-width: 1400px) {
  .about-us-middle-section {
    flex-direction: row;
    margin: 20px 0;
  }

  .middle-section-image-container {
    margin-left: 30%;
    border-radius: 10px;
    overflow: hidden;
  }

  .middle-section-detail {
    width: 46%;
    left: 30%;
  }
}

@media (min-width: 1400px) {
  .middle-section-detail {
    width: 40%;
    left: 28%;
  }

  .middle-section-detail p {
    width: 60%;
  }
}
