.artist-list {
  margin: 2% 0;
}

/* @media (min-width: 960px) {
  .artist-list {
    flex-direction: row;
    flex-wrap: wrap;
  }
} */
